import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider, Helmet } from "react-helmet-async";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import { worker } from "./mocks/browser";

// worker.start();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
      <HelmetProvider>
          <Helmet>
              <script
                  async
                  src="https://www.googletagmanager.com/gtag/js?id=G-5B5JT8HM4M"
              />
              <script>
                  {`
                      window.dataLayer = window.dataLayer || [];
                      function gtag(){dataLayer.push(arguments);}
                      gtag('js', new Date());
                      gtag('config', 'G-5B5JT8HM4M');
                  `}
              </script>
          </Helmet>
          <App />
        </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
